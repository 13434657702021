import { createApp } from 'vue'
import { Quasar, quasarUserOptions } from './plugin/quasar'
import './styles/index.scss'
import '@novu/notification-center-vue/dist/style.css'
import App from './index.vue'
import { store, router, translate, notification } from './providers'

const app = createApp(App)

// @ts-ignore
window.$appInstance = app

// @ts-ignore
app.use(Quasar, quasarUserOptions)
app.use(translate(() => callback()))
app.use(router)
app.use(store)
app.use(notification)

let callback: any = null
function onAppReady(cb: any) {
  callback = cb
}

export { app, onAppReady }
