import { UserAuthority } from '@/shared/types/roles'

export type UserPermission =
  | 'COPY_APPLICATION'
  | 'COPY_DASHBOARD'
  | 'CREATE_ACTIVATION_RULES'
  | 'CREATE_APPLICATION'
  | 'CREATE_DASHBOARD'
  | 'DELETE_ACTIVATION_RULES'
  | 'DELETE_APPLICATION'
  | 'DELETE_DASHBOARD'
  | 'EDIT_ACCESS_GROUP_APPLICATIONS'
  | 'EDIT_ACCESS_GROUP_ETL_MIDDLEWARE'
  | 'EDIT_ACCESS_GROUP_EXPERIMENTS'
  | 'EDIT_ACCESS_GROUP_PATTERNS'
  | 'EDIT_ACCESS_GROUP_TABLES'
  | 'EDIT_ACCESS_GROUP_SCHEMAS'
  | 'EDIT_ACCESS_GROUP_SYSTEM_DATASOURCES'
  | 'EDIT_ACCESS_GROUP_EXTERNAL_DATASOURCES'
  | 'EDIT_ACTIVATION_RULES'
  | 'EDIT_APPLICATION'
  | 'EDIT_DASHBOARD'
  | 'EDIT_DASHBOARD_PREVIEW'
  | 'PUBLISH_APPLICATION'
  | 'UNPUBLISH_APPLICATION'
  | 'VIEW_ACCESS_GROUP_APPLICATIONS'
  | 'VIEW_ACTIVATION_RULES'
  | 'VIEW_APPLICATION'
  | 'VIEW_APPLICATIONS'
  | 'VIEW_DASHBOARD'
  | 'VIEW_UNPUBLISHED_APPLICATIONS'

const permissionAuthorities: Record<UserPermission, UserAuthority[]> = {
  COPY_APPLICATION: ['S_APPLICATIONS_DASHBOARDS_COPY_APPLICATION'],
  COPY_DASHBOARD: ['S_APPLICATIONS_DASHBOARDS_COPY_DASHBOARD', 'S_APPLICATIONS_DASHBOARDS_ALL_APPLICATIONS'],
  CREATE_ACTIVATION_RULES: ['S_WIDGETS_ACTIVATION_RULES_CREATE'],
  CREATE_APPLICATION: ['S_APPLICATIONS_DASHBOARDS_CREATE_APPLICATION'],
  CREATE_DASHBOARD: ['S_APPLICATIONS_DASHBOARDS_CREATE_DASHBOARD'],
  DELETE_ACTIVATION_RULES: ['S_WIDGETS_ACTIVATION_RULES_DELETE'],
  DELETE_APPLICATION: ['S_APPLICATIONS_DASHBOARDS_DELETE_APPLICATION'],
  DELETE_DASHBOARD: ['S_APPLICATIONS_DASHBOARDS_DELETE_DASHBOARD'],
  EDIT_ACCESS_GROUP_APPLICATIONS: ['S_APPLICATIONS_DASHBOARDS_EDIT_ACCESS_GROUP'],
  EDIT_ACCESS_GROUP_ETL_MIDDLEWARE: ['S_ETL_MIDDLEWARE_EDIT_ACCESS_GROUP'],
  EDIT_ACCESS_GROUP_EXPERIMENTS: ['S_ML_EXPERIMENTS_EDIT_ACCESS_GROUP'],
  EDIT_ACCESS_GROUP_PATTERNS: ['S_ML_PATTERNS_EDIT_ACCESS_GROUP'],
  EDIT_ACCESS_GROUP_TABLES: ['S_DATASOURCES_ACCESS_GROUPS_SET_TABLES'],
  EDIT_ACCESS_GROUP_SCHEMAS: ['S_DATASOURCES_ACCESS_GROUPS_SET_SCHEMAS'],
  EDIT_ACCESS_GROUP_SYSTEM_DATASOURCES: ['S_DATASOURCES_ACCESS_GROUPS_SET_SYSTEM_DATASOURCES'],
  EDIT_ACCESS_GROUP_EXTERNAL_DATASOURCES: ['S_DATASOURCES_ACCESS_GROUPS_SET_SYSTEM_DATASOURCES'],
  EDIT_ACTIVATION_RULES: ['S_WIDGETS_ACTIVATION_RULES_UPDATE'],
  EDIT_APPLICATION: ['S_APPLICATIONS_DASHBOARDS_GET_ONE_APPLICATION', 'S_APPLICATIONS_DASHBOARDS_UPDATE_APPLICATION'],
  EDIT_DASHBOARD: ['S_APPLICATIONS_DASHBOARDS_GET_ONE_DASHBOARD', 'S_APPLICATIONS_DASHBOARDS_UPDATE_DASHBOARD'],
  EDIT_DASHBOARD_PREVIEW: ['S_APPLICATIONS_DASHBOARDS_UPDATE_DASHBOARD_PREVIEW'],
  PUBLISH_APPLICATION: ['S_APPLICATIONS_DASHBOARDS_PUBLISH_APPLICATION'],
  UNPUBLISH_APPLICATION: ['S_APPLICATIONS_DASHBOARDS_UNPUBLISH_APPLICATION'],
  VIEW_ACCESS_GROUP_APPLICATIONS: [
    'S_APPLICATIONS_DASHBOARDS_VIEW_ACCESS_GROUP',
    'S_APPLICATIONS_DASHBOARDS_ALL_APPLICATIONS',
  ],
  VIEW_ACTIVATION_RULES: ['S_WIDGETS_ACTIVATION_RULES_GET_BY_DASHBOARD'],
  VIEW_APPLICATION: [
    'S_APPLICATIONS_DASHBOARDS_GET_ONE_APPLICATION',
    'S_APPLICATIONS_DASHBOARDS_APPLICATION_GET_DASHBOARDS',
  ],
  VIEW_APPLICATIONS: ['S_APPLICATIONS_DASHBOARDS_ALL_APPLICATIONS'],
  VIEW_DASHBOARD: ['S_APPLICATIONS_DASHBOARDS_GET_ONE_DASHBOARD'],
  VIEW_UNPUBLISHED_APPLICATIONS: ['S_APPLICATIONS_DASHBOARDS_APPLICATIONS_GET_UNPUBLISHED'],
}

export function getAuthorities(permission: UserPermission): UserAuthority[] {
  return permissionAuthorities[permission]
}
